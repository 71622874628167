<ng-scrollbar shown="hover" compact="true">
    <sidebar-user></sidebar-user>

    <ul class="navigation">
        <!-- <li *ngFor="let menu of mainMenu; let i = index" [class.navigation__sub]="menu.subMenu" routerLinkActive="navigation__active">
            <a *ngIf="!menu.subMenu" [routerLink]="[menu.ruta]" (click)="menu.subMenu && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icono]"></i> {{ menu.titulo }}
            </a>
            <a *ngIf="menu.subMenu" (click)="menu.subMenu && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icono]"></i> {{ menu.titulo }}
            </a>
            <ul *ngIf="menu.subMenu" [@toggleSubMenu]="menu.visibility">
                <li *ngFor="let sub of menu.subMenu" routerLinkActive="navigation__active">
                    <a [routerLink]="[sub.ruta]">{{ sub.titulo }}</a>
                </li>
            </ul>
        </li> -->

        <li *ngFor="let menu of mainMenu; let i = index" [class.navigation__sub]="menu.subMenu" routerLinkActive="navigation__active">
            <!-- Router link for non sub menu  -->
            <a *ngIf="!menu.subMenu" [routerLink]="[menu.ruta]" (click)="menu.subMenu && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icono]"></i> {{ menu.titulo }}
            </a>
            <!-- / -->

            <!-- Menu toggle for sub menu -->
            <a *ngIf="menu.subMenu" (click)="menu.subMenu && toggleSubMenu(i)">
                <i [ngClass]="['zmdi zmdi-'+menu.icono]"></i> {{ menu.titulo }}
            </a>
            <!-- / -->

            <ul *ngIf="menu.subMenu" [@toggleSubMenu]="menu.visibility">
                <li *ngFor="let sub of menu.subMenu; let j = index" [class.navigation__sub_sub]="sub.subMenu" routerLinkActive="navigation__active">
                        <!-- Router link for non sub menu  -->
                        <a *ngIf="!sub.subMenu" [routerLink]="[sub.ruta]" (click)="sub.subMenu && toggleSubSubMenu(i,j)">
                            <i [ngClass]="['zmdi zmdi-'+sub.icono]"></i> {{ sub.titulo }}
                        </a>
                        <!-- / -->

                        <!-- Menu toggle for sub menu -->
                        <a *ngIf="sub.subMenu" (click)="sub.subMenu && toggleSubSubMenu(i,j)">
                            <i [ngClass]="['zmdi zmdi-'+sub.icono]"></i> {{ sub.titulo }}
                        </a>
                        <!-- / -->
                        <ul *ngIf="sub.subMenu" [@toggleSubSubMenu]="sub.visibility">
                            <li *ngFor="let sub2 of sub.subMenu" routerLinkActive="navigation__active">
                                <a [routerLink]="[sub2.ruta]">{{ sub2.titulo }}</a>
                            </li>
                        </ul>
                </li>
            </ul>
        </li>
    </ul>
</ng-scrollbar>