import { EncryptionService } from "./../helpers/encryption-service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { Usuario } from "@app/model/usuario";
import * as jwt_decode from "jwt-decode";
import { ValidarPermisoRequest } from "@app/model/requests/validarPermisoRequest";
import { ValidarPermisoResponse } from "@app/model/responses/validarPermisoResponse";
import { Sucursal } from "@app/model/sucursal";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;

  private currentSucursalSubject: BehaviorSubject<Sucursal>;
  public currentSucursal: Observable<Sucursal>;

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {
    let localUser = localStorage.getItem("currentUser");
    this.currentUserSubject = new BehaviorSubject<Usuario>(
      localUser ? JSON.parse(this.encryptionService.decrypt(localUser)) : null
    );
    this.currentUser = this.currentUserSubject.asObservable();

    let localSucursal = localStorage.getItem("sucursal");
    this.currentSucursalSubject = new BehaviorSubject<Sucursal>(
      localSucursal
        ? JSON.parse(this.encryptionService.decrypt(localSucursal))
        : null
    );
    this.currentSucursal = this.currentSucursalSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  public get currentSucursalIdValue(): number {
    return this.currentSucursalSubject.value.idSucursal;
  }

  public get currentSucursalValue(): Sucursal {
    return this.currentSucursalSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrl}Api${environment.autenticarPath}`, {
        nombreUsuario: username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem(
            "currentUser",
            this.encryptionService.encrypt(JSON.stringify(user))
          );
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  obtenerMenu(idUsuario: number) {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.menuPath}?id=${idUsuario}`)
      .pipe(
        map((menu: Array<any>) => {
          return menu;
        })
      );
  }

  validarPermisoOpcion(siglaOpcion: string, idTipoDocumento: number = null, esCargaMasiva: boolean = false) {
    let request = new ValidarPermisoRequest();
    request.siglaOpcion = siglaOpcion;
    request.idTipoDocumento = idTipoDocumento;
    request.esCargaMasiva = esCargaMasiva;
    return this.http
      .post<any>(
        `${environment.baseUrl}Api${environment.validarPermisoPath}`,
        request
      )
      .pipe(
        map((response: ValidarPermisoResponse) => {
          return response;
        })
      );
  }

  validarAccion(idAccion: number) {
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.validarAccionPath}/${idAccion}`
      )
      .pipe(
        map((response: any) => {
          return response.tieneAcceso;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("sucursal");
    this.currentUserSubject.next(null);
    this.currentSucursalSubject.next(null);
  }

  logoutSucursal() {
    // remove user from local storage to log user out
    localStorage.removeItem("sucursal");
    this.currentSucursalSubject.next(null);
  }

  isLoggedIn() {
    // remove user from local storage to log user out
    if (this.currentUserValue && !this.isTokenExpired()) return true;

    return false;
  }

  isLoggedInSucursal() {
    // remove user from local storage to log user out
    if (this.currentSucursalValue) return true;

    return false;
  }

  getToken(): string {
    return this.currentUserValue.token;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  asignarSucursalUsuario(value: Sucursal) {
    let sucursalEncriptada = this.encryptionService.encrypt(
      JSON.stringify(value)
    );
    localStorage.setItem("sucursal", sucursalEncriptada);
    this.currentSucursalSubject.next(value);
  }

  actualizarSucursalDefecto(idSucursal: number) {
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.actualizarSucursalDefectoPath}/${idSucursal}`
      )
      .pipe(
        map((respuesta: any) => {
          var user = this.currentUserValue;

          localStorage.removeItem("currentUser");
          this.currentUserSubject.next(null);
          user.idSucursalDefecto = idSucursal;

          localStorage.setItem(
            "currentUser",
            this.encryptionService.encrypt(JSON.stringify(user))
          );
          this.currentUserSubject.next(user);
          return respuesta;
        })
      );
  }
}
