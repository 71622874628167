<div class="user">
    <div class="user__info" (click)="userMenu = !userMenu" [class.active]="userMenu">
        <img class="user__img" src="{{rutaFoto}}" alt="Foto usuario">        
        <div class="user__name">{{usuario.nombreusu}}</div>
        <div class="user__email">{{usuario.email}}</div>
    </div>

    <div *ngIf="userMenu" [@toggleUserMenu]="userMenu" class="user__menu">
        <!-- <a [routerLink]="">Mi cuenta</a> -->
        <a (click)="cerrarSesion()">Cerrar sesión</a>
    </div>
</div>