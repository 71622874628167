import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OpcionesService } from "@app/services/opciones.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "search.component.html",
  styleUrls: ["search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchValue: string = "";
  searchActive: boolean = false;
  searchFocused: boolean = false;
  @Input() mobileSearchStatus: boolean;
  @Output() mobileSearchStatusChange = new EventEmitter();

  resetSearch() {
    
    if (this.searchValue) {
      this.opcionesService.obtenerOpcionSigla(this.searchValue).subscribe({
        next: (value) => {
          if (value) {
            this.searchActive = false;
            this.searchValue = null;
            this.searchFocused = false;

            this.router.navigate([value.ruta]);
          }
        },
        error: (error) => {
          this.toastr.error(error, "Error");
          this.searchActive = false;
          this.searchValue = null;
          this.searchFocused = false;
        },
      });
    } else {
      this.searchActive = false;
      this.searchValue = null;
      this.searchFocused = false;
    }
  }

  closeSearch() {
    this.mobileSearchStatus = false;
    this.mobileSearchStatusChange.emit(false);
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private opcionesService: OpcionesService
  ) {}

  ngOnInit() {}
}
