import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Siete Plus 2020';

  
}

window.addEventListener('storage', (event) => {
  window.location.href = "/";
});