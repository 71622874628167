import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { DataTableResponse } from "@app/model/responses/dataTableResponse";
import { Opcion } from "@app/model/opcion";
import { TipoOpcion } from "@app/model/tipoOpcion";
import { ComboResponse } from "@app/model/responses/comboResponse";
import { FavoritoRequest } from "@app/model/requests/favoritoRequest";
import { Favorito } from "@app/model/seguridad/favorito";

const ParseHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

@Injectable({
  providedIn: "root",
})
export class OpcionesService {
  constructor(private http: HttpClient) {}

  obtenerOpciones(dataTablesParameters: any) {
    return this.http
      .post<any>(
        `${environment.baseUrl}Api${environment.opcionesPath}`,
        dataTablesParameters,
        ParseHeaders
      )
      .pipe(
        map((Opciones: DataTableResponse) => {
          return Opciones;
        })
      );
  }

  obtenerSecuencia() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.opcionSecuenciaPath}`)
      .pipe(
        map((respuesta: any) => {
          return respuesta;
        })
      );
  }

  obtenerOpcion(id: number) {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.opcionPath}/${id}`)
      .pipe(
        map((Opcion: Opcion) => {
          return Opcion;
        })
      );
  }

  obtenerOpcionSigla(sigla: string) {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.opcionSiglaPath}/${sigla}`)
      .pipe(
        map((Opcion: Opcion) => {
          return Opcion;
        })
      );
  }

  crearOpcion(Opcion: Opcion) {
    return this.http
      .post<any>(`${environment.baseUrl}Api${environment.opcionPath}`, Opcion)
      .pipe(
        map((id: number) => {
          return id;
        })
      );
  }

  editarOpcion(Opcion: Opcion) {
    return this.http
      .put<any>(`${environment.baseUrl}Api${environment.opcionPath}`, Opcion)
      .pipe();
  }

  eliminarOpcion(id: number) {
    return this.http
      .delete<any>(`${environment.baseUrl}Api${environment.opcionPath}/${id}`)
      .pipe();
  }

  obtenerComboTipossOpciones() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.tiposOpcionesPath}`)
      .pipe(
        map((tiposOpciones: Array<ComboResponse>) => {
          return tiposOpciones;
        })
      );
  }

  obtenerFavoritos() {
    
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.favoritosUsuarioPath}`)
      .pipe(
        map((response: Array<Favorito>) => {
          return response;
        })
      );
  }

  obtenerFavorito(idOpcion: number) {
    
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.favoritosPath}/${idOpcion}`)
      .pipe(
        map((response: Favorito) => {
          return response;
        })
      );
  }

  favorito(request: FavoritoRequest) {
    
    return this.http
      .post<any>(`${environment.baseUrl}Api${environment.favoritosPath}`, request)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
