import { Router } from '@angular/router';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Usuario } from './../../../model/usuario';
import { environment } from '@environments/environment';
import {ApiS3Service} from "@app/services/api-s3.service";
import {Read_Delete_FileRequest} from "@app/model/requests/apiFileS3/Read_Delete_FileRequest";
import { ReadFileResponse } from "@app/model/responses/apiFileS3/ReadFileResponse";
import { error } from 'console';
@Component({
  selector: 'sidebar-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [
    trigger('toggleUserMenu', [
      state('void', style({
        height: '0',
        opacity: '0'
      })),
      state('true', style({
        height: '*',
        opacity: '1'
      })),
      transition(':enter', animate('200ms ease-in')),
      transition(':leave', animate('200ms ease-out'))
    ])
  ]
})
export class UserComponent implements OnInit {
  userMenu: boolean = false;
  usuario: Usuario;
  rutaFoto: string = "";
  stringBase64: string;
  bucketname: string = "t4c-webforms";
  parametros: Read_Delete_FileRequest;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router, private apiFileS3: ApiS3Service) { 
      this.parametros = new Read_Delete_FileRequest();
    }

  ngOnInit() {
    this.usuario = this.authenticationService.currentUserValue;        
    //this.rutaFoto = `${environment.urlFotos}/empleados/${this.usuario.identificacion}.jpg`;
    //Llamado apiS3AWS
    this.parametros.bucketName = this.bucketname;
    this.parametros.path = environment.pathFotosEmpleados + this.usuario.identificacion + ".jpg";
    this.loadData(); // Llama al método para cargar los datos
  }

  cerrarSesion() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  loadData() {
      this.apiFileS3.readFile(this.parametros).subscribe(
        { next:(respuesta) => {
          this.rutaFoto = `data:image/jpeg;base64,${respuesta.bytes}`
        },
        error: (error) => {
          console.log(error)
        },
      });
  }
}
