
export const environmentBuild = {
  environmentBuild: "production",
  environmentColor : "#004E94",

  //AWS
  baseUrl: "https://sieteplus-backend.tiipuc.org/",//backend
  apiUrl: "https://sieteplus-backend.tiipuc.org/api", //  backend
  servidorAplicacion:"https://webforms.tiipuc.org/", // WebForms

};


