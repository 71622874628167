import { environmentBuild } from "./environment.production";

const environmentVariables = {

  production: false,
  IpLan: "10.10.0.30",
  ambiente: "/Produccion/",
  ambiente2: "Produccion",
  ambienteVisualizar: "Producción 0.1",

  //security
  key: "S13t3P$#@$^@1DEV", //Debe ser de 16 caracteres
  //nombreAplicacion: "SietePlusV2",
  nombreAplicacion: "SietePlus2020",
  carpetaReportesV2: "SietePlusV2",
  carpetaReportes2020: "SietePlus2020",
  nombreEmpresa: "IPUC",

  reportesUrlPublicoReportViewer:"http://reportes.tiipuc.org/ReportServer",
  reportesUrlLanReportViewer: "http://reportes.tiipuc.org/ReportServer",
  reportesUrlPublico:"http://reportes.tiipuc.org/ReportServer/Pages/ReportViewer.aspx?",
  reportesUrlLan: "http://reportes.tiipuc.org/ReportServer/Pages/ReportViewer.aspx?",
  urlFotos: "https://v2020.sieteplus.net/fotos/",
  apiUrlECM: "https://ecm.tiipuc.org/",
  apiUrlBaseS3: "https://fs3.tiipuc.org/",
  
  //Inicio ruta EndPoint S3 AWS
  rutaUpFile: "File/UpFile",
  rutaReadFile: "File/ReadFile",
  rutaDeleteFile: "File/DeleteFile",
  rutaRenameFile: "File/rename",
  pathFotosEmpleados: "Archivos/fotos/empleados/",
  //Fin ruta EndPoint S3 AWS

  //Inicio Seguridad
  autenticarPath: "/seguridad/seguridad/autenticar",
  validarPermisoPath: "/seguridad/seguridad/validarpermiso",
  validarAccionPath: "/seguridad/seguridad/accion",
  gruposOpcionesPath: "/seguridad/gruposOpciones/gruposOpciones",
  grupoOpcionPath: "/seguridad/gruposOpciones/grupoOpcion",
  opcionesPath: "/seguridad/opciones/opciones",
  opcionPath: "/seguridad/opciones/opcion",
  opcionSecuenciaPath: "/seguridad/opciones/secuencia",
  opcionSiglaPath: "/seguridad/opciones/opcionsigla",
  tiposOpcionesPath: "/seguridad/opciones/tiposopciones",
  usuariosPath: "/seguridad/usuarios/usuarios",
  usuarioPath: "/seguridad/usuarios/usuario",
  usuarioCodigoPath: "/seguridad/usuarios/usuarioCodigo",
  menuPath: "/seguridad/seguridad/menu",
  rolesPath: "/seguridad/roles/roles",
  rolPath: "/seguridad/roles/rol",
  rolOpcionPath: "/seguridad/rolesopciones/rolopcion",
  rolOpcionTodosPath: "/seguridad/rolesopciones/rolopcion/todos",
  permisosRolesOpcionesPath: "/seguridad/rolesopciones/permisosrolesopciones",
  usuarioRolTodosPath: "/seguridad/usuariosroles/usuariorol/todos",
  usuarioRolPath: "/seguridad/usuariosroles/usuariorol",
  rolesUsuarioPath: "/seguridad/usuariosroles/rolesusuario",
  rolesCentrosCostosPath: "/seguridad/rolessubcentroscostos",
  rolesCentroCostoPath: "/seguridad/rolessubcentroscostos/rolsubcentrocosto",
  tokenPath: "/seguridad/token/crear",
  rolesCentrosCostosTodosPath:
    "/seguridad/rolessubcentroscostos/rolsubcentrocosto/todos",
  favoritosPath: "/seguridad/seguridad/favorito",
  favoritosUsuarioPath: "/seguridad/seguridad/favoritos",
  actualizarSucursalDefectoPath: "/seguridad/seguridad/actualizarsucursal",
  //Fin Seguridad

  //Inicio Ahorros
  comboPeriodosAhorrosPath: "/ahorros/maestros/comboperiodosahorro",
  obtenerPastoresEnvioExtracto:"/ahorros/ahorros/obtenerpastoresenvioextracto",
  enviarEmailExtractoPastores:"/ahorros/ahorros/enviaremailextractopastores",
  //Fin Inicio

  //Inicio Generales
  tiposDocumentoPath: "/generales/tiposDocumentos/tiposDocumentos",
  tipoDocumentoPath: "/generales/tiposDocumentos/tipoDocumento",
  tipoDocumentoATPath: "/generales/tiposDocumentos/tipoDocumentoAT",
  empresasUsuarioPath: "/generales/maestros/empresasusuario",
  tokenECMPath: "/generales/maestros/tokenecm",
  sucursalPath: "/generales/maestros/sucursal",
  sucursalesEmpresaPath: "/generales/maestros/sucursalesempresa",
  sucursalesUsuarioPath: "/generales/maestros/sucursalesusuario",
  comboTiposReferenciaPath: "/generales/maestros/combotiposreferencia",
  comboEstadosCodumentoGeneralPath:
    "/generales/maestros/comboestadosdocumentogeneral",
  comboTiposSujetoPath: "/generales/maestros/combotipossujeto",
  comboConfirmacionPath: "/generales/maestros/comboconfirmacion",
  comboNaturalezaPath: "/generales/maestros/combonaturaleza",
  comboCajasPath: "/generales/maestros/combocajas",
  comboCuentasBancoPath: "/generales/maestros/combocuentasbancarias",
  comboGrupoTipoObligacionPath: "/generales/maestros/combogrupotipoobligacion",
  comboTipoObligacionPath: "/generales/maestros/combotipoobligacion",
  comboTipoObligacionRPath: "/generales/maestros/combotipoobligacionr",
  comboRubroRPath: "/generales/maestros/comborubror",
  comboTiposPeriodoPath: "/generales/maestros/combotiposperiodo",
  comboPlanesExequialesPath: "/generales/maestros/comboplanesexequiales",
  comboParentescosPath: "/generales/maestros/comboparentescos",
  comboPlanesPath: "/generales/maestros/comboplanes",
  comboComboCoberturasPath: "/generales/maestros/combocoberturas",
  comboSeriesECMPath:"/generales/maestros/comboseriesecm",
  comboTiposDocumentoECMPath:"/generales/maestros/combotiposdocumentoecm",
  comboDistritosPath:"/generales/maestros/combodistritos",
  comboTipoIdPath:"/generales/maestros/combotipoid",
  comboTipoPersonaPath:"/generales/maestros/combotipopersona",
  periodoAño:"/contabilidad/contabilidad/periodoAño",
  formularioGenerico: "/generales/ControlUsuarioMaestros/controlUsuario",
  siglaFormularioGenerico: "/seguridad/RolesOpciones/sigla",
  //Fin Generales

  //Inicio Contabilidad
  tiposComprobantesPath: "/contabilidad/tiposComprobantes/tiposComprobantes",
  tipoComprobantePath: "/contabilidad/tiposComprobantes/tipoComprobante",
  tipoComprobanteATPath: "/contabilidad/tiposComprobantes/tipoComprobanteAT",
  ejecutarScriptPath: "/contabilidad/tiposComprobantes/ejecutarScript",
  ejecutarScriptArchivoPath:
    "/contabilidad/tiposComprobantes/ejecutarScriptArchivo",
  crearSecuenciasPath: "/contabilidad/tiposComprobantes/crearSecuencias",
  comprobantePath: "/contabilidad/comprobantes/comprobante",
  comprobantesPath: "/contabilidad/comprobantes",
  cargaComprobantesPath: "/contabilidad/cargaMasivaComprobantes",
  comboEstadosCargaComprobantesPath:
    "/contabilidad/cargaMasivaComprobantes/comboestadoscargacomprobantes",
  detalleConceptosPath: "/contabilidad/comprobantes/detalle",
  obtenerConfiguracionDocumento:
    "/contabilidad/comprobantes/configuraciondocumento",
  validacionesGuardarPath: "/contabilidad/comprobantes/validacionesguardar",
  validacionesModificarPath: "/contabilidad/comprobantes/validacionesmodificar",
  comboCoceptosDocumentoPath:
    "/contabilidad/comprobantes/comboconceptoscomprobante",
  configuracionConceptoPath: "/contabilidad/comprobantes/configuracionconcepto",
  conceptoDocumentoPath: "/contabilidad/comprobantes/concepto",
  conceptoDocumentoRelacionadoPath: "/contabilidad/comprobantes/conceptorelacionado",
  copiarComprobantePath: "/contabilidad/comprobantes/copiar",
  procesarCierrePath: "/contabilidad/comprobantes/procesarCierre",
  obtenerPeriodoPath: "/contabilidad/contabilidad/periodo",
  obtenerPrimeroUltimoPath: "/contabilidad/comprobantes/primeroultimo",
  obtenerCarteraPagarPath: "/contabilidad/comprobantes/carterapagar",
  obtenerCarteraPagarRPath: "/contabilidad/comprobantes/carterapagarr",
  obtenerCuotasPagadasPath: "/contabilidad/comprobantes/cuotaspagadas",
  obtenerCuotasRPagadasPath: "/contabilidad/comprobantes/cuotasrpagadas",
  obtenerAbonosReversarPath: "/contabilidad/comprobantes/abonosreversar",
  obtenerAbonosReversarRPath: "/contabilidad/comprobantes/abonosreversarr",
  obtenerAbonosReversadosPath: "/contabilidad/comprobantes/abonosreversados",
  obtenerAbonosRReversadosPath: "/contabilidad/comprobantes/abonosrreversados",
  obtenerCuentasComprobantesPath: "/contabilidad/comprobantes/cuentas",
  obtenerTerceros: "/contabilidad/contabilidad/obtenerterceros",
  enviarEmailReteFuente:"/contabilidad/contabilidad/enviaremailretefuente",
  obtenerReteFuente:"/contabilidad/contabilidad/obtenerretefuente",
  obtenerPlantillaCargaPath: "/contabilidad/contabilidad/obtenerplantillacarga",
  obtenerArchivoCargaPath: "/contabilidad/contabilidad/obtenerarchivocarga",
  //Fin Contabilidad

  //Inio Tesoreria
  obtenerCodigoChequePath: "/tesoreria/cheques/codigo",
  getPagosPse: "/tesoreria/rilespagopse/obtenerlistapagopse",
  comparePagosPse: "/tesoreria/rilespagopse/comparePagoPse",
  getComboEstado:"/tesoreria/rilespagopse/comboEstados",
  postPagoPse: "/tesoreria/rilespagopse/postPagoPse",
  obtenercomboEstadosCargaPath:"/tesoreria/Nivelacion/comboestados",
  obtenerComboPeriodosPath:"/tesoreria/Nivelacion/ComboPeriodos",
  obtenerPlantillaCargaNivelacion:"/tesoreria/Nivelacion/obtenerplantillacarga",
  insertarCargaNivelacion:"/tesoreria/Nivelacion/insertar",
  subirDatosNivelacion:"/tesoreria/Nivelacion/subir",
  postguardarCuponPath:"/tesoreria/cupones/agregarcupon",
  obtenerCuponesPath: "/tesoreria/cupones/obtenercuponxsucursal",
  eliminarCuponesPath: "/tesoreria/Cupones/eliminar",
  ObtenerPlantillaCuponesPath: "/tesoreria/Cupones/obtenerplantillacupon",
  //Fin Tesoreria

  //Inicio Nomina

  comboEstadosCotizacionDiasEmpleadoPath: "/nomina/maestros/comboestadoscargacotizaciondiasempleado",
  comboPeriodosNominaPath: "/nomina/maestros/comboperiodos",
  cotizacionDiasEmpleadoEncabezadoPath: "/nomina/cotizaciondiasempleado/cotizaciondiasempleadoencabezado",
  insertarCargaMasivaCotizacionDiasEmpleadoPath: "/nomina/cotizaciondiasempleado/insertar",
  actualizarCargaMasivaCotizacionDiasEmpleadoPath: "/nomina/cotizaciondiasempleado/actualizar",
  insertarCargaMasivaNominaManualPath: "/nomina/NominaManual/insertar",
  GetIdCargaMasivaNominaManualPath: "/nomina/NominaManual/cargamasivanominamanual",
  actualizarCargaMasivaNominaManualPath: "/nomina/NominaManual/actualizar",
  obtenerPlantillaCargaNominaPath: "/nomina/NominaManual/obtenerplantillacarga",
  obtenerArchivoCargaNominaPath: "/nomina/NominaManual/obtenerarchivocarga",

  insertarCargaMasivaHoraExtraEmpleadoPath:"/nomina/NovedadHoraExtraEmpleado/insertarcargamasivahoraextraempleado",
  obtenerCargaMasivaHoraExtraEmpleadoPath:"/nomina/NovedadHoraExtraEmpleado/cargahoraextraempleado",
  actualizarCargaMasivaHoraExtraEmpleadoPath:"/nomina/NovedadHoraExtraEmpleado/actualizarcargamasivahoraextraempleado",
  //Fin Nomina

  //Inicio carteraR
  obligacionesRPath: "/cartera/obligacionesr",
  obligacionesTodosRPath: "/cartera/obligacionesr/obligaciones/todos",
  obtenerEstadosRPath: "/cartera/obligacionesr/obligaciones/estados",
  obtenerTiposObligacionRRPath: "/cartera/obligacionesr/obligaciones/tipos",
  obtenerTiposArchivoComboPath: "/carterar/tipoArchivo/combo",
  obtenerConfiguracionRRPath:
    "/cartera/obligacionesr/obligaciones/configuracion",
  validarAbonosObligacionRRPath:
    "/cartera/obligacionesr/obligaciones/validarabonos",
  obtenerCuotasObligacionRRPath: "/cartera/obligacionesr/obligaciones/cuotas",
  calcularCuotasObligacionRRPath:
    "/cartera/obligacionesr/obligaciones/calcularcuotas",
  calcularBitacoraCuotasRPath:
    "/cartera/obligacionesr/obligaciones/bitacoracuotas",
  obtenerComboNotasPath: "/contabilidad/revelaciones/combonotas",
  obtenerRevelacionesPath: "/contabilidad/revelaciones/obtener",
  configuracionesRPath: "/carterar/configuracionr/configuracionesr",
  configuracionRPath: "/carterar/configuracionr/configuracionr",
  itemsFinancierosPath: "/cartera/itemsfinancieros",

  tipoObligacionPath: "/Cartera/tipoObligacionAnual/ComboTiposObligaciones",
  estadosCargaObligacionPath: "/Cartera/tipoObligacionAnual/ComboEstadosCargaObligacionesAnuales",

  rubroPath: "/carterar/rubros",
  solicitudesPath: "/cartera/solicitudes",
  pagaresPath: "/cartera/pagares",
  tipoArchivoPath: "/carterar/tipoarchivo",
  tipoArchivotipoObligacionPath: "/carterar/tipoarchivotipoobligacion",

  insertarCargaMasivaObligacionesAnualesPath: "/Cartera/tipoObligacionAnual/Insertar",
  
  actualizarCargaMasivaObligacionesAnualesPath: "/Cartera/tipoObligacionAnual/actualizar",  
  getIdCargaMasivaObligacionesAnualesPath: "/Cartera/tipoObligacionAnual/cargamasivaobligacionesanuales", 

  obtenerValoresTemplos: "/Cartera/tipoObligacionesAnual/ObtenerValoresTemplosCarga",

  //Fin carteraR

  //ECM
  seriePath: "/Series/ConsultarSeries",
  //Fin ECM



  //Inicio Seguros
  planPath: "/seguros/planes",
  coberturaPath:"/seguros/coberturas",
  configuracionPath:"/seguros/configuraciones",
  //Fin Seguros

  //Inicio Busqueda
  parametrosBusquedaPath: "/generales/busqueda/parametros",
  condicionesBusquedaPath: "/generales/busqueda/condicionesBusqueda",
  valoresBusquedaPath: "/generales/busqueda/valores",
  resultadosBusquedaPath: "/generales/busqueda/resultados",
  obtenerTerceroCodigoPath: "/generales/busqueda/tercerocodigo",
  obtenerTemploCodigoPath: "/generales/busqueda/templocodigo",
  obtenerSucursalCodigoPath: "/generales/busqueda/sucursalcodigo",
  obtenerOrdenPagoCodigoPath: "/generales/busqueda/ordenpagocodigo",
  obtenerOrdenCompraCodigoPath: "/generales/busqueda/ordencompracodigo",
  obtenerDocumentoCodigoPath: "/generales/busqueda/documentocodigo",
  obtenerConceptoDocumentoGeneralIdPath:
    "/generales/busqueda/conceptodocumentogeneral",
  obtenerCodRefDuplicadaPath:"generales/busqueda/codigoRef",
  obtenerConceptoDocumentoGeneralCuentaPath:
    "/generales/busqueda/conceptodocumentogeneralcuenta",
  obtenerSubCentroCostoPath: "/generales/busqueda/subcentrocosto",
  obtenerCuentaConceptoCajaPath: "/generales/busqueda/cuentaconceptocaja",
  obtenerCuentaConceptoCuentaBancoPath:
    "/generales/busqueda/cuentaconceptocuentabanco",
  obtenerPredioCodigoPath: "/generales/busqueda/cargarpredio",
  obtenerInformacionContablePredioPath:
    "/generales/busqueda/informacioncontablepredio",
  obtenerConceptoPath: "/generales/busqueda/concepto",
  //Fin Busqueda


  //Inicio nivelacionBaseTipoPastor
  obtenerPeriodosNivelacionPath: "/tesoreria/NivelacionesBaseLicencia/periodos",
  obtenerNivelacionPorPeriodoPath:"/tesoreria/NivelacionesBaseLicencia/filtroPeriodo/",
  IdNivelacionesPath:"/tesoreria/NivelacionesBaseLicencia/",
  //Fin nivelacionBaseTipoPastor

  // seguros
  exequialesPath: "/seguros/Exequiales",
  planExequialPath: "/seguros/PlanExequial",
  // Seguros

  //Correspondencia
  insertDespachada: "correspondencia/Despachadas/create",
  actualizarDespachada: "correspondencia/Despachadas/update",
  eliminarDespachadas: "correspondencia/Despachadas/delete",
  consultarDespachada: "correspondencia/Despachadas",
  comboDistritos: "correspondencia/Despachadas/combodistritos",
  comboClaseP: "correspondencia/Despachadas/comboClaseP",
  obtenerComboPais: "correspondencia/Despachadas/comboPais",
  obtenerComboEstado: "correspondencia/Despachadas/comboEstados",
  obtenerComboFormaE: "correspondencia/Despachadas/comboFormaE",
  obtenerComboFicina: "correspondencia/Despachadas/comboDespachos",
  obtenerComboDto: "correspondencia/Despachadas/comboDepartamento",
  obtenerComboMunicipio: "correspondencia/Despachadas/comboMunicipio",

  insertarRecibida: "correspondencia/Recibida/create",
  eliminarRecibida: "correspondencia/Recibida/delete",
  actualizarRecibida: "correspondencia/Recibida/update",
  consultarRecibida: "correspondencia/Recibida",
  obtenerComboEstad: "correspondencia/Recibida/comboEstados",
  obtenerComboTd: "correspondencia/Recibida/comboTipoDoc",
  obtenerComboUs: "correspondencia/Recibida/comboUsuario",
  obtenerComboPaisR: "correspondencia/Recibida/comboPais",
  obtenerComboDtoR: "correspondencia/Recibida/comboDepartamento",
  obtenerComboMunicipioR: "correspondencia/Recibida/comboMunicipio",

  consultarTramite: "correspondencia/Tramite",
  actualizarEstado: "correspondencia/Tramite",

  insertarClaseProducto: "/correspondencia/ClaseProducto/create",
  obtenerClaseporId: "/correspondencia/ClaseProducto",
  actualizarClaseProducto:"/correspondencia/ClaseProducto/update",
  eliminarClaseProducto:"/correspondencia/ClaseProducto/delete",

  insertarFormasEnvio: "/correspondencia/FormasEnvio/create",
  consultarFormasEnvio: "/correspondencia/FormasEnvio",
  actualizarFormasEnvio:"/correspondencia/FormasEnvio/update",
  eliminarFormasEnvio:"/correspondencia/FormasEnvio/delete",

  insertarOficinaDespachos: "/correspondencia/Despachos/create",
  consultarOficinaDespachos: "/correspondencia/Despachos",
  actualizarOficinasDespachos:"/correspondencia/Despachos/update",
  eliminarOficinasDespachos:"/correspondencia/Despachos/delete",

  insertarTipoDocumentos: "/correspondencia/TipoDocumento/create",
  consultarTipoDocumentos: "/correspondencia/TipoDocumento",
  actualizarTipoDocumentos:"/correspondencia/TipoDocumento/update",
  eliminarTipoDocumentos:"/correspondencia/TipoDocumento/delete",

  //datatable language
  espanol: {
    processing: "Procesando...",
    search: "Buscar:",
    searchPlaceholder: "Buscar...",
    lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último",
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente",
    },

  },
  
};


export const environment = Object.assign(environmentVariables,environmentBuild);
