import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  rutaFoto: string = "";
  // Default theme
  maTheme: string = 'blue-grey';

  // Mobile sidebar state
  mobileSidebarActive: boolean = false;

  // Contacts
  contactsItems: any[] = [
   // {
      //name: 'Eddier Rivera',
      //email: 'jsistemas@ipuc.org.co',
      //exte: 'Extensión 1024',
     // img: `${environment.urlFotos}/empleados/16747710.jpg`
   //},
    //{
      //name: 'Walter Castro',
      //email: 'sistemas1@ipuc.org.co',
      //exte: 'Extensión 1135',
      //img: `${environment.urlFotos}/empleados/1037605507.jpg`
    //},
    //{
      //name: 'Marleny Guiral',
      //email: 'sistemas2@ipuc.org.co',
      //exte: 'Extensión 1046',
      //img: `${environment.urlFotos}/empleados/1037572691.jpg`
    //},
    //{
      //name: 'Sergio Medina',
      //email: 'sistemas3@ipuc.org.co',
      //exte: 'Extensión 1082',
      //img: `${environment.urlFotos}/empleados/1020449867.jpg`
    //}
  ];

  // Todolists
  todoLists: Array<any> = [];
  todoListActions: Array<any> = [
    'Mark as completed',
    'Delete'
  ];
}
