<footer class="footer hidden-xs-down">
    <p class="Título">SOPORTE 7PLUS</p>
    
    <p>MARLENY GUIRAL MARÍN   
      <i _ngcontent-c35="" class="zmdi zmdi-local-post-office zmdi-hc-fw"></i>marleny.guiral@ipuc.org.co
      <i _ngcontent-c35="" class="zmdi zmdi-local-phone zmdi-hc-fw"></i>Ext: 1046 | COORDINADORA T.I</p>
    <p>WALTER CASTRO ESCOBAR
      <i _ngcontent-c35="" class="zmdi zmdi-local-post-office zmdi-hc-fw"></i>walter.castro@ipuc.org.co 
      <i _ngcontent-c35="" class="zmdi zmdi-local-phone zmdi-hc-fw"></i>Ext: 1135 | DESARROLLADOR</p>
    <p>YEFFRI GÓMEZ HINESTROZA
      <i _ngcontent-c35="" class="zmdi zmdi-local-post-office zmdi-hc-fw"></i>yeffri.gomez@ipuc.org.co 
       <i _ngcontent-c35="" class="zmdi zmdi-local-phone zmdi-hc-fw"></i>Ext: 1043 | DESARROLLADOR</p>
    <p>ANDREY RAMIREZ CHACON
      <i _ngcontent-c35="" class="zmdi zmdi-local-post-office zmdi-hc-fw"></i>andrey.ramirez@ipuc.org.co 
      <i _ngcontent-c35="" class="zmdi zmdi-local-phone zmdi-hc-fw"></i>Ext: 1090 | DESAROLLADOR</p>

    <p>JUAN CAMILO GIRALDO
      <i _ngcontent-c35="" class="zmdi zmdi-local-post-office zmdi-hc-fw"></i>juan.giraldo@ipuc.org.co 
      <i _ngcontent-c35="" class="zmdi zmdi-local-phone zmdi-hc-fw"></i>Ext: 1092 | SOPORTE MESA DE AYUDA</p>
  <br>
  <p> {{nombreEmpresa}} -  {{nombreSistema}} . Todos los derechos reservados.</p>  
  <p>Desarrollado por alhucave@gmail.com</p>
  <ul class="nav footer__nav">
    <a class="nav-link" [routerLink]="['/home']" >Inicio</a>
  </ul>
</footer> 