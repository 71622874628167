import { Component, OnInit } from "@angular/core";
import { AppService } from "../../app.service";
import { Favorito } from "@app/model/seguridad/favorito";
import { ToastrService } from "ngx-toastr";
import { OpcionesService } from "@app/services/opciones.service";
import { environment } from "@environments/environment";
import { AuthenticationService } from "@app/services/authentication.service";
import { Sucursal } from "@app/model/sucursal";
import { Router } from "@angular/router";
import { MaestrosService } from "@app/services/maestros.service";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit {
  maCurrentTheme: string;
  maThemes: Array<string>;
  appShortcuts: Array<any>;
  tasks: Array<any>;
  favoritos: Array<Favorito>;
  mobileSearchActive: boolean = false;
  ambienteVisualizar: string = environment.ambienteVisualizar;
  sucursalActual: Sucursal;
  environmentColor: String;

  constructor(
    private service: AppService,
    private toastr: ToastrService,
    private opcionesService: OpcionesService,
    private router: Router,
    private maestroService: MaestrosService,
    private authenticationService: AuthenticationService
  ) {
    // Retrieve current theme from AppService
    this.maCurrentTheme = service.maTheme;

    // App shortcut data
    this.appShortcuts = [
      {
        appTitle: "Calendar",
        className: "bg-red",
        icon: "zmdi-calendar",
      },
      {
        appTitle: "Files",
        className: "bg-blue",
        icon: "zmdi-file-text",
      },
      {
        appTitle: "Email",
        className: "bg-teal",
        icon: "zmdi-email",
      },
      {
        appTitle: "Reports",
        className: "bg-blue-grey",
        icon: "zmdi-trending-up",
      },
      {
        appTitle: "News",
        className: "bg-orange",
        icon: "zmdi-view-headline",
      },
      {
        appTitle: "Gallery",
        className: "bg-light-green",
        icon: "zmdi-image",
      },
    ];

    // Available themes
    this.maThemes = [
      "green",
      "blue",
      "red",
      "teal",
      "cyan",
      "blue-grey",
      "purple",
      "indigo",
    ];

    // Tasks
    this.tasks = [
      {
        name: "HTML5 Validation Report",
        completed: 95,
        color: "primary",
      },
      {
        name: "Google Chrome Extension",
        completed: "80",
        color: "success",
      },
      {
        name: "Social Intranet Project",
        completed: "20",
        color: "warning",
      },
      {
        name: "Bootstrap Admin Template",
        completed: "60",
        color: "danger",
      },
      {
        name: "Youtube Client App",
        completed: "80",
        color: "info",
      },
    ];
  }

  // Set theme
  setMaTheme() {
    this.service.maTheme = this.maCurrentTheme;
  }

  ngOnInit() {
    this.environmentColor=environment.environmentColor;

    this.sucursalActual = this.authenticationService.currentSucursalValue;
    this.opcionesService.obtenerFavoritos().subscribe({
      next: (value) => {
        
        this.favoritos = value;
      },
      error: (error) => {
        this.toastr.error(error, "Error");
      },
    });
  }

  actualizarSucursalDefecto(idSucursal: number) {
    
    this.authenticationService.actualizarSucursalDefecto(idSucursal).subscribe({
      next: (value) => {},
      error: (error) => {
        this.toastr.error(error, "Error");
      },
    });
  }

  cambiarSucursal() {
    this.authenticationService.logoutSucursal();
    this.router.navigate(["/loginsucursal"]);
  }

  abrirECM() {
    
    this.maestroService
    .obtenerTokenECM("0","0")
    .subscribe({
      next: (token) => {
        
        if (token) {
          
          var url = `${environment.apiUrlECM}Seguridad/SietePlusTokenLogin?token= ` + token.idLista.toString();
           
          window.open(
            url,
            "_blank"      
          );

        }
      },
      error: (error) => {
        this.toastr.error(error, "Error");
      },
    });
  }  
}
