import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Read_Delete_FileRequest } from "@app/model/requests/apiFileS3/Read_Delete_FileRequest";
import { UpFileRequest } from "@app/model/requests/apiFileS3/UpFileRequest";
import { RenameFileRequest } from "@app/model/requests/apiFileS3/RenameFileRequest";
import { ReadFileResponse } from "@app/model/responses/apiFileS3/ReadFileResponse";
import { UpFile_RenameResponse } from "@app/model/responses/apiFileS3/UpFile_RenameResponse";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiS3Service {
  bucketName: string = "t4c-webforms";
  archivoResultadoUrl: string;
  constructor(private http: HttpClient) {

   }

  readFile(parametros: Read_Delete_FileRequest){
    try {
      const apiUrlBase: string = environment.apiUrlBaseS3;
      const path: string = environment.rutaReadFile;
      const urlString: string = apiUrlBase + path;

      // Definir los encabezados
      const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Cambia esto según el tipo de contenido que estés enviando
      'u': 'ler789oi..!!333',
      'c': 'ddffgr098..8u78!!'
      });

      return this.http.post<any>(urlString, parametros, {headers}).pipe(map((respuesta: ReadFileResponse) => {return respuesta;}));
    } catch (error) {
      
      throw error;
    }
    
   }

}
