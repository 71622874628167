import { Injectable } from "@angular/core";
import { AuthenticationService } from "@app/services/authentication.service";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        debugger;
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          //en la nueva version el metodo reload no requiere parametros
          //location.reload(true);
          location.reload();
        }

        if (err.status === 403) {
          this.router.navigate(["/403"]);
        }

        console.log(err.error);

        const error = err.error || "Server error";
        return throwError(error);
      })
    );
  }
}
