import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {

    encrypt(value){
        var key = CryptoJS.enc.Utf8.parse(environment.key);
        var iv = CryptoJS.enc.Utf8.parse(environment.key);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return encrypted.toString();
    }

    decrypt(value){
        var key = CryptoJS.enc.Utf8.parse(environment.key);
        var iv = CryptoJS.enc.Utf8.parse(environment.key);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}