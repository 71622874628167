import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    //private jwtHelper: JwtHelper,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    if (this.authenticationService.isLoggedIn()) {
      if(!this.authenticationService.isLoggedInSucursal()){
        this.router.navigate(['/loginsucursal'], { queryParams: { returnUrl: state.url } });
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authenticationService.logout();
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
