import { Sucursal } from "./../model/sucursal";
import { Empresa } from "./../model/empresa";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { ComboResponse } from "@app/model/responses/comboResponse";
import { TipoComprobante } from "@app/model/contabilidad/tipoComprobante";
import { Maestro } from "@app/model/maestro";
import { TokenECM } from "@app/model/seguridad/tokenECM";
import { Plan } from "@app/model/seguros/plan";

@Injectable({
  providedIn: "root",
})
export class MaestrosService {


  obtenerTokenECM(codigo: string,tipo: string) {

    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.tokenECMPath}?codigo=${codigo}&tipo=${tipo}`
      )
      .pipe(
        map((token: TokenECM) => {
          return token;
        })
      );

  }
  constructor(private http: HttpClient) {}

  obtenerEmpresasUsuario(cedula: string) {
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.empresasUsuarioPath}?cedula=${cedula}`
      )
      .pipe(
        map((empresas: Array<Empresa>) => {
          return empresas;
        })
      );
  }

  obtenerSucursalesEmpresa(idEmpresa: number, cedula: string) {
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.sucursalesEmpresaPath}?id=${idEmpresa}&cedula=${cedula}`
      )
      .pipe(
        map((sucursales: Array<Sucursal>) => {
          return sucursales;
        })
      );
  }

  obtenerSucursalesUsuario(idUsuario: number) {
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.sucursalesUsuarioPath}?idUsuario=${idUsuario}`
      )
      .pipe(
        map((sucursales: Array<Sucursal>) => {
          return sucursales;
        })
      );
  }

  obtenerSucursal(idSucursal: number) {
    
    return this.http
      .get<any>(
        `${environment.baseUrl}Api${environment.sucursalPath}?idSucursal=${idSucursal}`
      )
      .pipe(
        map((sucursal: Sucursal) => {
          return sucursal;
        })
      );
  }

 
  obtenerComboTiposReferencia() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboTiposReferenciaPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboEstadosDocumentoGeneral() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboEstadosCodumentoGeneralPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboTiposSujeto() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboTiposSujetoPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboConfirmacion() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboConfirmacionPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboNaturaleza() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboNaturalezaPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboCajas() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboCajasPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboCuentasBancarias() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboCuentasBancoPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboGrupoTipoObligacion() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboGrupoTipoObligacionPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboTipoObligacion() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboTipoObligacionPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboTipoObligacionR() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboTipoObligacionRPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboRubroR() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboRubroRPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

  obtenerComboTiposPeriodo() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboTiposPeriodoPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }

 
  obtenerComboPlanes() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboPlanesPath}`)
      .pipe(
        map((Respuesta: Array<Plan>) => {
          return Respuesta;
        })
      );
  }

  obtenerComboCoberturas() {
    return this.http
      .get<any>(`${environment.baseUrl}Api${environment.comboComboCoberturasPath}`)
      .pipe(
        map((combo: Array<ComboResponse>) => {
          return combo;
        })
      );
  }
}
