import { EncryptionService } from "./helpers/encryption-service";
import { Layout2Component } from "./layout/layouts/layout-2/layout.component";
import { Layout1Component } from "./layout/layouts/layout-1/layout.component";
import { Jwtinterceptor } from "./helpers/jwt-interceptor";
import { ErrorInterceptor } from "./helpers/error-interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { SearchComponent } from "./layout/header/search/search.component";
import { LogoComponent } from "./layout/header/logo/logo.component";
import { NavigationTriggerComponent } from "./layout/header/navigation-trigger/navigation-trigger.component";
import { UserComponent } from "./layout/sidebar/user/user.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AppService } from "./app.service";
import { SafeHtml } from './pipe/safeHtml';
import { DataTablesModule } from "angular-datatables";
//import { CommonModule } from "@angular/common";



@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    Layout1Component,
    Layout2Component,
    SearchComponent,
    LogoComponent,
    NavigationTriggerComponent,
    UserComponent,
    PageLoaderComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgScrollbarModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    //CommonModule
  ],
  providers: [
    AppService,
    EncryptionService,
    { provide: HTTP_INTERCEPTORS, useClass: Jwtinterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
