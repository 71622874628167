import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '@app/services/authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class Jwtinterceptor implements HttpInterceptor {

  constructor(
   // private jwtHelper: JwtHelper,
    private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      //if (!this.jwtHelper.isTokenExpired(currentUser.token)) {
        //console.log(this.jwtHelper.decodeToken(token));
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`
          }
        });
      //}
    }

    return next.handle(request);
  }
}
